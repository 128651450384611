import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { PackagesComponent } from './pages/packages/packages.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ProductsComponent } from './pages/products/products.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { PartnershipComponent } from './pages/partnership/partnership.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule }   from '@angular/forms';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { HttpClientModule } from '@angular/common/http';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { BannerComponent } from './components/banner/banner.component';
import { ScrollTopComponent } from './components/scroll-top/scroll-top.component';
import { SidePopupComponent } from './components/side-popup/side-popup.component';
import { CoockiesPolicyBannerComponent } from './components/coockies-policy-banner/coockies-policy-banner.component';
import { CookiesPolicyComponent } from './pages/cookies-policy/cookies-policy.component';
import { TechnicalServicesComponent } from './pages/technical-services/technical-services.component';
import { ThanksComponent } from './pages/thanks/thanks.component';
import { PrivancyPolicyComponent } from './pages/privancy-policy/privancy-policy.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    PackagesComponent,
    AboutUsComponent,
    ProductsComponent,
    ContactUsComponent,
    PartnershipComponent,
    ContactFormComponent,
    SnackbarComponent,
    BannerComponent,
    ScrollTopComponent,
    SidePopupComponent,
    CoockiesPolicyBannerComponent,
    CookiesPolicyComponent,
    TechnicalServicesComponent,
    ThanksComponent,
    PrivancyPolicyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

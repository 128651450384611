import { Component, OnInit } from '@angular/core';
import { ContactFormDTO, ContactFormValidator, IEmail, EmailMetadata } from 'src/app/shared/interfaces';
import { DataService } from 'src/app/shared/data.service';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';

import {environment} from 'src/environments/environment'
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-popup',
  templateUrl: './side-popup.component.html',
  styleUrls: ['./side-popup.component.scss']
})
export class SidePopupComponent implements OnInit {

  animatedClass: string;
  oneTimer: any;
  showPrButton: string;

  contactForm: ContactFormDTO = {};
  contactFormValidator: ContactFormValidator = {};

  submitDisabled: boolean;
  email: IEmail;
  emailData: EmailMetadata;
  checked: boolean;

  constructor(private dataService: DataService,
              private snackbarService: SnackbarService,
              private router: Router) { }

  ngOnInit() {
    this.emailData = {
      from: 'website@titan-t.com',
      to: 'titantechnology19@gmail.com',
      subject: 'Email from Promotion'
    }

    this.checked = true;
  }

  onSubmit(){
    if(!this.nameValidate() || !this.phoneValidate() || !this.emailValidate())
      return;

    this.submitDisabled = true;
    this.send();
  }

  send(){
    const emailBody = `<div>
                          <div>Full Name: ${this.contactForm.fullName}</div>
                          <div>Email Addres: ${this.contactForm.email}</div>
                          <div>Phone number: ${this.contactForm.phoneNumber}</div>
                        </div>`;
                        
    this.email = {
      subject: this.emailData.subject,
      from: this.emailData.from , 
      to: this.emailData.to, 
      content: emailBody
    }

    this.dataService.sendEmailService(this.email).subscribe(obj=>{
      this.closePopup();

      if(environment.thankyouPage){
        this.router.navigate(['thanks']);
      }
      else {
        this.snackbarService.show('Thank you for the interest in our company. Our representative will get in touch with you shortly.');
      }
    });

  }


  nameValidate(): boolean{
    this.contactFormValidator.fullName = false; 
    if(!this.contactForm.fullName || this.contactForm.fullName == ''){
      this.contactFormValidator.fullName = true;
      return;
    }
    return true;
  }

  phoneValidate(): boolean{
    this.contactFormValidator.phoneNumber = false;
    if(!this.contactForm.phoneNumber || this.contactForm.phoneNumber == ''){
      this.contactFormValidator.phoneNumber = true;
      return;
    }
    return true;
  }

  emailValidate(): boolean{
    this.contactFormValidator.email = false;
    if(!this.contactForm.email || this.contactForm.email == ''){
      this.contactFormValidator.email = true;
      return
    }
    return true;
  }


  showPopup(){
    this.showPrButton = 'bounceOutDown';
    setTimeout(()=> {
      this.animatedClass = 'zoomInRight rightSide';
    }, 200);
  }

  closePopup(){
    this.animatedClass = 'zoomOutRight rightSide';
    setTimeout(()=> {
      this.showPrButton = 'bounceInUp';
    }, 200);
    this.resetForm();
  }

  resetForm(){
    this.submitDisabled = false;
    this.contactForm.fullName = '';
    this.contactForm.email = '';
    this.contactForm.phoneNumber = '';
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { PackagesComponent } from './pages/packages/packages.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { ProductsComponent } from './pages/products/products.component';
import { PartnershipComponent } from './pages/partnership/partnership.component';
import { CookiesPolicyComponent } from './pages/cookies-policy/cookies-policy.component';
import { TechnicalServicesComponent } from './pages/technical-services/technical-services.component';
import { ThanksComponent } from './pages/thanks/thanks.component';
import { PrivancyPolicyComponent } from './pages/privancy-policy/privancy-policy.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'packages', component: PackagesComponent},
  { path: 'technical-services', component: TechnicalServicesComponent},
  { path: 'about-us', component: AboutUsComponent},
  { path: 'partnership', component: PartnershipComponent},
  { path: 'products', component: ProductsComponent},
  { path: 'contact-us', component: ContactUsComponent},
  { path: 'cookies-policy', component: CookiesPolicyComponent },
  { path: 'thanks', component: ThanksComponent },
  { path: 'privacy-policy', component: PrivancyPolicyComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
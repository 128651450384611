import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  constructor() { }

  animationDuration = 7000;

  banOneAnimationOne = '';
  banOneAnimationTwo = '';
  banOneAnimationThree = '';
  banOneAnimationImage = '';

  banner = {
    one: 'none',
    two: 'none',
    three: 'none'
  }

  count = 1;

  oneTimer;
  twoTimer;

  ngOnInit() {
    this.playFirstAnimation();
  }

  clickSlideButton(param){
    clearTimeout(this.oneTimer);
    clearTimeout(this.twoTimer);

    this.count = param;
    this.playFirstAnimation();
  }


  playFirstAnimation(){

    if(this.count == 1){
      this.banner.one = '';
      this.banner.two = 'none';
      this.banner.three = 'none';
    }

    if(this.count == 2){
      this.banner.one = 'none';
      this.banner.two = '';
      this.banner.three = 'none';
    }

    if(this.count == 3){
      this.banner.one = 'none';
      this.banner.two = 'none';
      this.banner.three = '';

      this.count = 0;
    }


    this.count++;

    this.banOneAnimationImage = 'animated fadeIn faster';

    this.banOneAnimationOne = 'animated fadeInDown faster';
    this.banOneAnimationTwo = 'animated fadeInUp delay-zero4s faster';
    this.banOneAnimationThree = 'animated fadeInDown delay-zero6s faster';

    

    this.oneTimer = setTimeout(() => {
      this.banOneAnimationImage = 'animated fadeOut faster';

      this.banOneAnimationOne = 'animated fadeOutUp faster';
      this.banOneAnimationTwo = 'animated fadeOutDown faster';
      this.banOneAnimationThree = 'animated fadeOutDown faster';
      this.twoTimer = setTimeout(()=> {
        this.playFirstAnimation()
      }, 400);
    }, this.animationDuration);

  }

}

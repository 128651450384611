import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ContactFormDTO, ContactFormValidator, IEmail, EmailMetadata, FormMetadata } from '../../shared/interfaces';
import { DataService } from '../../shared/data.service';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';

import {environment} from 'src/environments/environment'
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  
  @Input() formData: FormMetadata;
  @Input() emailData: EmailMetadata;

  email: IEmail;

  contactForm: ContactFormDTO = {};
  contactFormValidator: ContactFormValidator = {};

  checked: boolean;

  constructor(private dataService: DataService,
              private snackbarService: SnackbarService,
              private router: Router) { }


  ngOnInit() { 
    this.checked = true;
  }

  onSubmit(){

    if(!this.formValidation()){
      return;
    }

    const emailBody = `<div>
                          <div>Full Name: ${this.contactForm.fullName}</div>
                          <div>Email Addres: ${this.contactForm.email}</div>
                          <div>Phone number: ${this.contactForm.phoneNumber}</div>
                          <div>Country: ${this.contactForm.country}</div>
                          <div>Message: ${this.contactForm.message}</div>
                        </div>`;

    this.email = {
      subject: this.emailData.subject,
      from: this.emailData.from , 
      to: this.emailData.to, 
      content: emailBody
    }


    this.dataService.sendEmailService(this.email).subscribe(obj=>{
      this.resetForm();

      if(environment.thankyouPage){
        this.router.navigate(['thanks']);
      }
      else {
        this.snackbarService.show('Thank you for the interest in our company. Our representative will get in touch with you shortly.');
      }
    });

  }

  valudateField(field){
    switch (field) {
      case 'fullName':
          if(this.contactForm.fullName == '' || this.contactForm.fullName == undefined){
            this.contactFormValidator.fullName = true;
          } else {
            this.contactFormValidator.fullName = false;
          }
        break;

        case 'email':
            if(this.contactForm.email == '' || this.contactForm.email == undefined){
              this.contactFormValidator.email = true;
            } else {
              this.contactFormValidator.email = false;
            }
        break;

        case 'country':
            if(this.contactForm.country == '' || this.contactForm.country == undefined){
              this.contactFormValidator.country = true;
            } else {
              this.contactFormValidator.country = false;
            }
        break;

        case 'phoneNumber':
            if(this.contactForm.phoneNumber == '' || this.contactForm.phoneNumber == undefined){
              this.contactFormValidator.phoneNumber = true;
            } else {
              this.contactFormValidator.phoneNumber = false;
            }
        break;
    
      default:
        break;
    }
  }

  formValidation(){

    var a = 0;
    if(this.contactForm.fullName == '' || this.contactForm.fullName == undefined){
      this.contactFormValidator.fullName = true;
      a++
    } else {
      this.contactFormValidator.fullName = false;
    }

    if(this.contactForm.email == '' || this.contactForm.email == undefined){
      this.contactFormValidator.email = true;
      a++
    } else {
      this.contactFormValidator.email = false;
    }

    if(this.contactForm.country == '' || this.contactForm.country == undefined){
      this.contactFormValidator.country = true;
      a++
    } else {
      this.contactFormValidator.country = false;
    }

    if(this.contactForm.phoneNumber == '' || this.contactForm.phoneNumber == undefined){
      this.contactFormValidator.phoneNumber = true;
      a++
    } else {
      this.contactFormValidator.phoneNumber = false;
    }

    if(a != 0){
      return false;
    }
      
    return true;
  }

  resetForm(){
    this.contactForm = {};
    this.contactFormValidator = {};
  }

}



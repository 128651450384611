import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmailMetadata, FormMetadata } from 'src/app/shared/interfaces';


@Component({
  selector: 'app-partnership',
  templateUrl: './partnership.component.html',
  styleUrls: ['./partnership.component.scss']
})
export class PartnershipComponent implements OnInit {

  form: FormGroup

  emailData: EmailMetadata;
  formData: FormMetadata;

  constructor() { }

  ngOnInit() {

    this.formData = {
      formTitle: 'Partnership',
      formSubtitle: 'Just leave your details and we will get back to you with the compatible offer.',
      formImage: '../../../assets/direct-small.svg',
      formColor: "#81B4DA"
    }

    this.emailData = {
      subject: 'Email from Website - Partnership',
      from: 'website@titan-t.com',
      to: 'titantechnology19@gmail.com'
    }

    this.form = new FormGroup({
      country: new FormControl(null, [Validators.required]),
      fullName: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      phoneNumber: new FormControl(null, [Validators.required, Validators.minLength(6)]),
      message: new FormControl(null, [Validators.required]),
    })
  }

  onSubmit(event){
    console.log(event);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router) { }

  openMenu: boolean = false;

  ngOnInit() {
        //close menu after change route
        this.router.events.subscribe((val) => {
          if(val instanceof NavigationEnd == true){
            this.openMenu = false;
          }
        });
  }


  openMenuFunc(){
    this.openMenu = !this.openMenu;
  }

}

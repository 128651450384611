import { Component, OnInit } from '@angular/core';
import { EmailMetadata, FormMetadata } from 'src/app/shared/interfaces';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor() { }

  emailData: EmailMetadata;
  formData: FormMetadata;


  ngOnInit() {

    this.formData = {
      formTitle: 'Contact Us',
      formSubtitle: '',
      formImage: '../../../assets/contact-image-small.svg',
      formColor: "#81B4DA"
    }

    this.emailData = {
      subject: 'Email from Website - Contact Us',
      from: 'website@titan-t.com',
      to: 'titantechnology19@gmail.com'
    }


  }

}
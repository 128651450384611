import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coockies-policy-banner',
  templateUrl: './coockies-policy-banner.component.html',
  styleUrls: ['./coockies-policy-banner.component.scss']
})
export class CoockiesPolicyBannerComponent implements OnInit {

  constructor() { }

  accepted: boolean;
  closedStrip: boolean;

  ngOnInit() {
    this.accepted  = (localStorage.getItem('acceptedCookies') == 'true') ? true : false;
      
  }

  acceptCookiesPolicy(){
    localStorage.setItem('acceptedCookies', 'true');
    this.accepted = true;
  }

  closeCookiesPolicy(){
    this.closedStrip = true;
  }

}
